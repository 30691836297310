<template >
    <div>
      
      <div v-if="knowledge">
         <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a  @click="routeTo('home')">Home</a></li>
          <li>Knowledge Details</li>
        </ol>
        <h2>Knowledge Details</h2>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Portfolio Details Section ======= -->
    <section id="portfolio-details" class="portfolio-details">
      <div class="container">

        <div class="row gy-4">

          <div class="col-lg-7" v-if="knowledge.file_type != 'pdf'">
          <h2>{{knowledge.header}}</h2>
            <div class="portfolio-details-slider swiper">
              <div class="swiper-wrapper align-items-center">

                <div  class="swiper-slide"  v-if="knowledge.file_type == 'pdf'">
                  <img :src="knowledge.tumblin" alt="">
                </div>
                <span v-if="knowledge.file_type == 'video'" v-html="knowledge.youtube"></span>

               
              </div>
             
            </div>
            <div class="portfolio-description">
              
              <span v-html="knowledge.details"></span>
            
            </div>
          </div>
          <div class="col-lg-7 center-element" v-if="knowledge.file_type == 'pdf'">
            <div id="book" class="view-cover" >
	<div class="main">
		<div class="book-font">
			<div class="book-cover" :style="{ 'background':'#012970 url('+knowledge.tumblin+') 0 130px no-repeat','background-size': 'contain'}">
				<h1 class="title">{{knowledge.header}}</h1>
				
			</div>
			<div class="book-cover-back"></div>
		</div>
		
		
		<div class="book-bone">
			<h2>Emily Bronte　Wuthering Heights</h2>
		</div>
		
	</div>
</div>
<br>
<br>

          </div>
          <div class="col-lg-5">
            <div class="portfolio-info">
              <h3>Resource information</h3>
              <ul>
               <li><strong>Title</strong>: {{knowledge.header}} </li>
                <li v-if="knowledge.knowledgeSectors"><strong>Sector</strong>: <span  v-for="(sector,index) in knowledge.knowledgeSectors" :key="index"><span v-if="index>0"> , </span>{{sector.sector.header}}</span></li>
                <!-- <li v-if="knowledge.sector"><strong>Sector</strong>: <span  v-for="(sector,index) in knowledge.sector" :key="index"><span v-if="index>0"> , </span>{{sector}}</span></li> -->
                
                <!-- <li><strong>Date</strong>: {{Date(knowledge.date)}}</li> -->
                
				<li><strong>Summary</strong>:<span class="detail-text" v-html="knowledge.details" ></span></li>
				<li><strong>Year</strong>: {{knowledge.years}}</li>
				<li v-if="knowledge.file_type == 'pdf'"><strong>Number of pages</strong>: {{knowledge.no_of_pages}}</li>
                <li style="text-transform: capitalize;"><strong>File Type</strong>: <span v-if="knowledge.file_type == 'pdf'">PDF</span><span v-if="knowledge.file_type != 'pdf'">{{knowledge.file_type}}</span></li>
                
                <li  v-if="knowledge.file_type == 'pdf'"><strong>File Size</strong>: {{(knowledge.file_size/1000000).toFixed(2)}} mb</li>
                <div  v-if="knowledge.file_type == 'pdf'" class="select-search-bar button-pill center-element ">
                  <a class="a-to-button" target="_blank" :href="knowledge.pdf" >
  <i class="bi bi-funnel"></i> Download PDF
</a>
                </div>
                
              </ul>
            </div>
           
          </div>
 
        </div>

      </div>
    </section><!-- End Portfolio Details Section -->
    </div></div>
    
</template>
<script>
import { getFieldById } from '../assets/js/service';
export default {
    metaInfo: {
    // Children can override the title.
    title: 'Knowledge',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Knowledge"},
      {property:"og:title" ,content:"First Consult Knowledge"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/knowledge"},


      {name:"twitter:title" ,content:"First Consult Knowledge"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/knowledge"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
      data() {
        return {
          knowledge : null,
        }
      },
      mounted() {
        if(this.$store.state.sigleKnowledge){
          this.knowledge = this.$store.state.sigleKnowledge;
        }else if(this.$route.params.id){
          
          getFieldById('Knowledge',this.$route.params.id).then(
            resp=>{
              this.knowledge = resp.data;
            }
          )
        }
        
        else{
          this.$router.push('/knowledge');
        }
      },
      methods: {
      routeTo(route){
      this.$router.push(route);
    },
    },
}
</script>
<style >

@media (max-width: 768px) {
	#book {
		width: 320px !important;
    
    }
	.book-font{
		width:320px !important; height:100% !important;
    
    }
    	.book-cover{
		width:320px !important; height:100% !important;
    }
   .book-bone{
    height:100% !important;
   }

}
    	.txt-right{
		text-align:right;
	}
.detail-text p span{
	color: black !important;
}
.detail-text p{
	color: black !important;
}
	#book {
		width: 420px;
		margin-bottom: 100px;
		position: relative;
		-webkit-transition-duration: .5s;
		-moz-transition-duration: .5s;
		-ms-transition-duration: .5s;
		-o-transition-duration: .5s;
		transition-duration: .5s;
		-webkit-perspective: 2000px;
		-moz-perspective: 2000px;
		-ms-perspective: 2000px;
		-o-perspective: 2000px;
		perspective: 2000px;
	}
	.main {
		width: 420px;
		height: 460px;
		color: #ffffff;
		position: relative;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transition-duration: .5s;
		-moz-transition-duration: .5s;
		-ms-transition-duration: .5s;
		-o-transition-duration: .5s;
		transition-duration: .5s;
	}

/* = Book Font
-------------------------------------------------------------- */
	.book-font{
		width:420px; height:100%;
		position:absolute; top:0; bottom:0;
		font-size:15px; text-align:center;
		text-shadow:0 2px 0 rgba(30,35,45,1);
		box-shadow:inset 3px 0 10px rgba(0,0,0,0.1); /* 给书本添加光照阴影 */
		z-index:10;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		-o-transform-style:preserve-3d;
		transform-style:preserve-3d;

		-webkit-transform-origin:0% 50%;
		-moz-transform-origin:0% 50%;
		-ms-transform-origin:0% 50%;
		-o-transform-origin:0% 50%;
		transform-origin:0% 50%;

		-webkit-transition-duration:.5s;
		-moz-transition-duration:.5s;
		-ms-transition-duration:.5s;
		-o-transition-duration:.5s;
		transition-duration:.5s;

		-webkit-transform:translate3d(0,0,25px); /* 对应关系-1 */
		-moz-transform:translate3d(0,0,25px);
		-ms-transform:translate3d(0,0,25px);
		-o-transform:translate3d(0,0,25px);
		transform:translate3d(0,0,25px);
	}
	.book-cover{
		width:420px; height:100%;
		overflow:hidden;
		position:absolute; top:0; bottom:0;
		background:#29303A url(http://www.bluesdream.com/case/css3/3d-book-showcase/images/img.jpg) 0 200px no-repeat;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		-o-transform-style:preserve-3d;
		transform-style:preserve-3d;

		-webkit-backface-visibility:hidden;
		-moz-backface-visibility:hidden;
		-ms-backface-visibility:hidden;
		-o-backface-visibility:hidden;
		backface-visibility:hidden;
	}
	.book-cover-back{
		width:420px; height:460px;
		position:absolute; top:0; bottom:0;
		background:#29303A;
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		-o-transform-style:preserve-3d;
		transform-style:preserve-3d;

		-webkit-backface-visibility:hidden;
		-moz-backface-visibility:hidden;
		-ms-backface-visibility:hidden;
		-o-backface-visibility:hidden;
		backface-visibility:hidden;

		-webkit-transform:rotate3d(0,1,0,-180deg);
		-moz-transform:rotate3d(0,1,0,-180deg);
		-ms-transform:rotate3d(0,1,0,-180deg);
		-o-transform:rotate3d(0,1,0,-180deg);
		transform:rotate3d(0,1,0,-180deg);
	}
	.title{
		margin:0px 0 0 0;
		    display: flex;
    align-items: end;
		font-size:20px;
		padding: 20px;
       overflow: hidden;
    height: auto;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    line-clamp: 5;
	}
	.author{
		height:20px;
		font-size:20px;
	}
	.publisher{
		width:100%;
		position:absolute;
		bottom:5px;
	}

/* = Book Page
-------------------------------------------------------------- */
	.book-page{
		width:415px; height:650px;
		line-height:20px;
		position:absolute; top:5px;
		z-index:9;
		box-shadow:inset 3px 0 10px rgba(0,0,0,0.1);
		-webkit-transform-style:preserve-3d;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		-o-transform-style:preserve-3d;
		transform-style:preserve-3d;

		-webkit-transition-duration:.5s;
		-moz-transform-style:preserve-3d;
		-ms-transform-style:preserve-3d;
		-o-transform-style:preserve-3d;
		transform-style:preserve-3d;

		-webkit-transform:translate3d(0,0,24px); /* 这里的Z坐标 一定要比封面页的低 否则旋转的时候 渲染时会导致层叠*/
		-moz-transform:translate3d(0,0,24px);
		-ms-transform:translate3d(0,0,24px);
		-o-transform:translate3d(0,0,24px);
		transform:translate3d(0,0,24px);
	}
	.page{
		height:500px;
		margin:30px 40px;
		overflow:hidden;
	}
	.book-page h3{
		font-size:14px;
		text-align:center;
		margin-bottom:14px;
	}
	.book-page p{
		font-size:13px;
		margin-bottom:14px;
	}
	.page-number{
		width:100%;
		font-size:13px;
		text-align:center;
		position:absolute;
		bottom:10px;
	}

/* = Book Back
-------------------------------------------------------------- */
	.book-back{
		width:420px;
		background:#29303A;
		position:absolute; top:0; bottom:0;
		color:white;
		-webkit-transform:rotate3d(0,1,0,-180deg) translate3d(0,0,25px);
		-moz-transform:rotate3d(0,1,0,-180deg) translate3d(0,0,25px);
		-ms-transform:rotate3d(0,1,0,-180deg) translate3d(0,0,25px);
		-o-transform:rotate3d(0,1,0,-180deg) translate3d(0,0,25px);
		transform:rotate3d(0,1,0,-180deg) translate3d(0,0,25px);
		z-index:8;
	}
	.book-back .description{
		font-size:14px;
		line-height:20px;
		margin-top:50px;
		padding:10px 20px 10px 30px;
		background:rgba(255,255,255,0.1);
	}
	.book-back p{
		padding:5px 0;
	}
	.isbn{
		position:absolute;
		bottom:20px; left:30px;
	}

/* = Book Bone
-------------------------------------------------------------- */
	.book-bone{
		width:50px;
		background:#29303A;
		box-shadow:1px 0 0 #29303A,-1px 0 0 #29303A; /* 旋转的时候 拼接处隐隐约约会有个缝隙 所以加上个阴影来填补它 */
		position:absolute;
		top:0; bottom:0; left:-25px;
		-webkit-transform:rotate3d(0,1,0,-90deg);
		-moz-transform:rotate3d(0,1,0,-90deg);
		-ms-transform:rotate3d(0,1,0,-90deg);
		-o-transform:rotate3d(0,1,0,-90deg);
		transform:rotate3d(0,1,0,-90deg);
	}
	.book-bone h2{
		width:530px;
		height:50px; line-height:50px; /* 对应关系-1 */
		font-size:14px; text-align:right;
		padding:0 15px;
		-webkit-transform-origin:0 0;
		-moz-transform-origin:0 0;
		-ms-transform-origin:0 0;
		-o-transform-origin:0 0;
		transform-origin:0 0;

		-webkit-transform:rotate(90deg) translateY(-50px); /* 对应关系-1 */
		-moz-transform:rotate(90deg) translateY(-50px);
		-ms-transform:rotate(90deg) translateY(-50px);
		-o-transform:rotate(90deg) translateY(-50px);
		transform:rotate(90deg) translateY(-50px);
	}
	.book-bone span{ font-size:14px; padding-right:20px;}

/* = Order
-------------------------------------------------------------- */
	.book-cover:after,.book-back:after{ /*  */
		content:"";
		width:3px;
		position:absolute; top:0; left:10px; bottom:0;
		background:rgba(0,0,0,0.06);
		box-shadow:1px 0 3px rgba(255, 255, 255, 0.1);
	}
	.book-page,.book-top,.book-right,.book-bottom{
		background:#EEEFE9;
	}
	.book-right{
		width:50px; height:550px;
		position:absolute; top:5px; right:-20px;
		box-shadow:0 1px 0 #EEEFE9,0 -1px 0 #EEEFE9;
		-webkit-transform:rotate3d(0,1,0,90deg);
		-moz-transform:rotate3d(0,1,0,90deg);
		-ms-transform:rotate3d(0,1,0,90deg);
		-o-transform:rotate3d(0,1,0,90deg);
		transform:rotate3d(0,1,0,90deg);
	}
	.book-top{
		width:415px; height:50px;
		position:absolute; top:-20px; left:0;
		-webkit-transform:rotate3d(1,0,0,90deg);
		-moz-transform:rotate3d(1,0,0,90deg);
		-ms-transform:rotate3d(1,0,0,90deg);
		-o-transform:rotate3d(1,0,0,90deg);
		transform:rotate3d(1,0,0,90deg);
	}
	.book-bottom{
		width:415px; height:50px;
		position:absolute; bottom:-20px; left:0;
		-webkit-transform:rotate3d(1,0,0,-90deg) translate3d(0,0,0);
		-moz-rotate3d:(1,0,0,-90deg) translate3d(0,0,0);
		-ms-rotate3d:(1,0,0,-90deg) translate3d(0,0,0);
		-o-rotate3d:(1,0,0,-90deg) translate3d(0,0,0);
		rotate3d:(1,0,0,-90deg) translate3d(0,0,0);
	}
	.book-right,.book-top,.book-bottom{
		-webkit-backface-visibility:hidden;
		-moz-backface-visibility:hidden;
		-ms-backface-visibility:hidden;
		-o-backface-visibility:hidden;
		backface-visibility:hidden;
	}

/* = Flip
-------------------------------------------------------------- */
	/* Cover */

	/* Cover Back */
	.view-back .main{
		-webkit-transform:rotate3d(0,1,0,180deg);
		-moz-transform:rotate3d(0,1,0,180deg);
		-ms-transform:rotate3d(0,1,0,180deg);
		-o-transform:rotate3d(0,1,0,180deg);
		transform:rotate3d(0,1,0,180deg);
	}

	/* Open */
	.open-book{
		-webkit-transform:translate3d(50%,0,0);
		-moz-transform:translate3d(50%,0,0);
		-ms-transform:translate3d(50%,0,0);
		-o-transform:translate3d(50%,0,0);
		transform:translate3d(50%,0,0);
	}
	.open-book  .book-font{
		-webkit-transform:translate3d(0,0,25px) rotate3d(0,1,0,-160deg);
		-moz-transform:translate3d(0,0,25px) rotate3d(0,1,0,-160deg);
		-ms-transform:translate3d(0,0,25px) rotate3d(0,1,0,-160deg);
		-o-transform:translate3d(0,0,25px) rotate3d(0,1,0,-160deg);
		transform:translate3d(0,0,25px) rotate3d(0,1,0,-160deg);
	}
	.open-book:hover .main{
		-webkit-transform:rotate3d(1,1,0,15deg);
		-moz-transform:rotate3d(1,1,0,15deg);
		-ms-transform:rotate3d(1,1,0,15deg);
		-o-transform:rotate3d(1,1,0,15deg);
		transform:rotate3d(1,1,0,15deg);
	}
	/* View Right */
	.view-rotate .main{
		-webkit-transition-duration:5s;
		-moz-transition-duration:5s;
		-ms-transition-duration:5s;
		-o-transition-duration:5s;
		transition-duration:5s;

		-webkit-transform:rotate3d(0,1,0,360deg);
		-moz-transform:rotate3d(0,1,0,360deg);
		-ms-transform:rotate3d(0,1,0,360deg);
		-o-transform:rotate3d(0,1,0,360deg);
		transform:rotate3d(0,1,0,360deg);
	}
	.view-rotate:hover .main{
		-webkit-transition-duration:8s;
		-moz-transition-duration:8s;
		-ms-transition-duration:8s;
		-o-transition-duration:8s;
		transition-duration:8s;

		-webkit-transform:rotate3d(0,1,0,-360deg);
		-moz-transform:rotate3d(0,1,0,-360deg);
		-ms-transform:rotate3d(0,1,0,-360deg);
		-o-transform:rotate3d(0,1,0,-360deg);
		transform:rotate3d(0,1,0,-360deg);
	}

/* = Opt
-------------------------------------------------------------- */
	#opt{
		width:150px;
		line-height:30px;
		text-align:center;
		padding:10px 10px 5px 10px;
		background:rgba(255,255,255,0.4);
		position:fixed; top:10%; right:6%;
		z-index:100px;
	}
	#opt:after{
		content:"";
		width:105%; height:104%;
		position:absolute; top:-4%; left:-5%;
		border:4px solid rgba(255,255,255,0.3);
		z-index:-1;
	}
	#opt dt{
		margin-bottom:5px;
		border-bottom:1px solid rgba(0,0,0,0.1);
	}
	#opt dd{
		color:#999;
		cursor:pointer;
		background:rgba(170,170,170,0.1);
		margin-bottom:5px;
	}
	#opt dd:hover{
		color:rgba(255,255,255,0.8);
		box-shadow:inset 2px 0 0 rgba(85,85,85,0.2);
		background:rgba(85,85,85,0.4);
	}
	#opt .cur{
		color:black;
		box-shadow:inset 2px 0 0 rgba(85,85,85,0.2);
		background:rgba(85,85,85,0.2);
	}
</style>